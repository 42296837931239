import React, { useEffect } from 'react'

const Game = () => {
  useEffect(() => {
    const handlePostMessage = (event) => {
      const { type, message } = event.data

      if (type !== 'score') return

      const data = {
        grade: message.grade,
        score: message.score,
        timePlayed: message.timePlayed,
        level: message.level,
        gameId: message.gameId,
        le: message.le,
        upgrades: message.upgrades,
        currentHp: message.currentHp,
        maxHp: message.maxHp,
      }

      fetch('/game/score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.debug('Sent score to backend:', data)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }

    window.addEventListener('message', handlePostMessage)

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handlePostMessage)
    }
  }, []) // Empty dependency array ensures this effect runs once on mount and cleanup on unmount

  return (
    <div className='relative h-svh md:h-full w-full'>
      <iframe
        src={process.env.REACT_APP_GAME_URL}
        allowFullScreen
        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-full'
      ></iframe>
    </div>
  )
}
export default Game
