import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { actions } from '../../../pages/AppReducers/app.reducers'
import { selectIsSidebarOpen } from '../../../pages/AppReducers'
import { sidebarNavData, bottomNavData } from './sidebar.navData'
import { selectUser } from '../Auth'
import NavbarLink from './NavbarLink'
import { collapseLeftIcon, collapseRightIcon, logo, closeIcon, menuIcon } from '../../../assets/icons'
import { ROUTES } from '../../../constants'
const { setIsSidebarOpen } = actions
const { PLAY } = ROUTES
const routesToHideNavbar = [PLAY]

const Navbar = () => {
  const dispatch = useDispatch()
  const containerRef = useRef()
  const location = useLocation()

  const user = useSelector(selectUser)
  const isSidebarOpen = useSelector(selectIsSidebarOpen)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [shouldNavbarHide, setShouldNavbarHide] = useState(true)
  const navData = [...sidebarNavData, ...bottomNavData]
  const displayName = user?.displayName || 'Username'

  useEffect(() => {
    const handleClick = (e) => {
      const isClickedOutside = containerRef.current.contains(e.target)
      setIsMenuOpen(isClickedOutside)
    }
    isMenuOpen && window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [isMenuOpen])

  useEffect(() => {
    const currentPath = location.pathname
    setShouldNavbarHide(routesToHideNavbar.includes(currentPath))
  }, [location])

  return (
    <div
      ref={containerRef}
      className={classNames('top-0 right-0 z-50 w-full', {
        'hidden md:block': shouldNavbarHide,
      })}
    >
      <div className='bg-black py-[22px] px-4 lg:flex hidden items-center gap-4 justify-between'>
        <button onClick={() => dispatch(setIsSidebarOpen(!isSidebarOpen))}>
          <img src={isSidebarOpen ? collapseLeftIcon : collapseRightIcon} width='22' alt='collapse' />
        </button>

        <span className='text-sm font-semibold text-secondary-light capitalize'>{`Welcome, ${displayName}`}</span>
      </div>
      <nav className='mobile-navbar relative z-30 lg:hidden'>
        <div className='bg-black py-[22px] px-8 flex items-center gap-4 justify-between'>
          <div className='brand'>
            <img src={logo} width={51} />
          </div>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <img src={isMenuOpen ? closeIcon : menuIcon} width={24} alt='menuIcon' />
          </button>
        </div>
        {isMenuOpen && (
          <div className='mobile-dropdown-menu px-8 py-4 border-t border-light-gray bg-black absolute w-full top-full start-0'>
            <ul className='navbar-nav' onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {navData.map((each) => (
                <NavbarLink each={each} key={each.id} />
              ))}
            </ul>
          </div>
        )}
      </nav>
    </div>
  )
}
export default Navbar
