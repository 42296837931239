export const ESCAPE = 'Escape'
export const KEYDOWN = 'keydown'
export const KEYUP = 'keyup'

export const MODAL_SIZE = {
  MD: 'medium',
  FULL: 'fullScreen',
  LG: 'largeScreen',
}

export const MODAL_POSITION = {
  TOP: 'top',
  CENTER: 'center',
}

export const MODALS = {
  ACCESS_DENIED_MODAL: 'accessDeniedModal',
  LOGIN_MODAL: 'loginModal',
  INITIAL_MODAL: 'initialModal',
  DISCLAIMER_MODAL: 'disclaimerModal',
}

export const TABS = {
  PLAY_NOW: 'Play Now',
  LEADER_BOARD: 'Leaderboard',
  DISCLAIMER: 'Disclaimer',
  LOG_OUT: 'Log Out',
}

export const ROUTES = {
  HOME: '/',
  LEADERBOARD: '/leaderboard',
  DISCLAIMER: '/disclaimer',
  PLAY: '/play',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PROFILE: '/profile',
}

export const EMPTY_STRING = ''
export const EMPTY_ARRAY = []
export const EMPTY_OBJECT = {}

export const DEFAULT_INT_VALUES = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  FIVE: 5,
}

export const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
}

export const INDEX = { FIRST: 0, SECOND: 1, THIRD: 2, FOURTH: 3, FIFTH: 4 }

export const IS_AUTHENTICATED = 'isAuthenticated'

export const FETCH_LEADERBOARD = 'fetch_leaderboard'
