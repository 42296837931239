import React from 'react'
import Modal from '../../Modal'

const AccessDeniedModal = () => {
  return (
    <Modal
      title='Unable to Access Digital Defender'
      subtitle={
        <>
          Oops, that login looks to be in error. If you need assistance logging in, please contact us at{' '}
          <a className='underline underline-offset-2 text-white decoration-1' href='mailto:clientu@intel.com'>
            clientu@intel.com.
          </a>
        </>
      }
    ></Modal>
  )
}
export default AccessDeniedModal
