import { isEmpty } from 'lodash'
import classNames from 'classnames'

import Spinner from '../organisms/Spinner'
import Card from './Card'
import { FETCH_LEADERBOARD } from '../../constants'

const Cards = ({ data, spinner }) => {
  return (
    <>
      <div
        className={classNames('flex items-center justify-center h-[200px]', {
          hidden: !spinner,
        })}
      >
        <Spinner name={FETCH_LEADERBOARD} />
      </div>
      {isEmpty(data) ? (
        <div
          className={classNames('w-full py-6 px-8 flex items-center justify-center text-white rounded-b-lg h-[200px]', {
            hidden: spinner,
          })}
        >
          <b>No Data to display</b>
        </div>
      ) : (
        <div className={classNames('md:w-full', { hidden: spinner })}>
          {data.map((item) => (
            <Card item={item} key={item.id} />
          ))}
        </div>
      )}
    </>
  )
}

export default Cards
