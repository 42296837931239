import { createSlice } from '@reduxjs/toolkit'
import { fetchUserProfile } from './auth.actions'

const initialState = {
  user: null,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state, action) {
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      if (!action.payload) return
      if (action.payload.user) {
        state.user = action.payload
      }
    })
  },
})

export default slice

export const { name, actions, reducer } = slice
