import thunk from 'redux-thunk'
import combinedReducers from './combinedReducers'
import { configureStore } from '@reduxjs/toolkit'

const storeConfiguration = (preloadedState) =>
  configureStore({
    reducer: combinedReducers,
    preloadedState,
    middleware: [thunk],
  })

export default storeConfiguration
