import { createSlice } from '@reduxjs/toolkit'
import { fetchLeaderboard } from './leaderboard.actions'

const initialState = {
  filters: { page: 1, pageSize: 10 },
  leaderboardData: null,
}

const slice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload || initialState.filters
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeaderboard.fulfilled, (state, action) => {
      if (!action.payload) return
      state.leaderboardData = action.payload || initialState.leaderboardData
    })
  },
})

export default slice

export const { name, actions, reducer } = slice
