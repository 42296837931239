import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import classNames from 'classnames'
import Modal from '../../Modal'
import Button from '../../../atoms/Button'
import { actions } from '../../Modal/modal.reducers'
import GameInsights from '../../GameInsights'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../../constants'
const { setSelectedModal } = actions
const { HOME, LEADERBOARD } = ROUTES

const InitialModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [shouldViewAllText, setShouldViewAllText] = useState(false)

  const handleClose = () => dispatch(setSelectedModal(null))

  const handleNavigation = (url) => {
    navigate(url)
    handleClose()
  }

  const footer = (
    <div className='flex justify-between md:justify-end'>
      <Button
        name='View Leaderboard'
        classes='text-blue-500 hover:bg-primary/10 content-end mr-1 bg-black'
        onClick={() => handleNavigation(LEADERBOARD)}
      />
      <Button name='Play Now' classes='text-white content-end w-[145px]' onClick={() => handleNavigation(HOME)} />
    </div>
  )

  return (
    <Modal title='Welcome to Digital Defender' footer={footer} handleClose={handleClose}>
      <GameInsights isRenderedInModal={true}>
        <div className='text-base text-secondary-light gap-1 overflow-auto'>
          <h3 className='subtitle text-white text-base leading-6 font-semibold mb-3'>
            A learning game based on ClientU curriculum, designed to enhance your learning while having fun.
          </h3>
          <div
            className={classNames(' md:block', {
              hidden: !shouldViewAllText,
              block: shouldViewAllText,
            })}
          >
            <h3 className='subtitle text-white text-base leading-6 font-semibold mb-3'>Accessibility</h3>
            <p className='mt-4'>
              To access commercial client training covering the launch of Intel® Core™ Ultra and Intel® Core™ processors
              (14th gen) that is accessible to users with disabilities, please visit the primary ClientU web portal at:{' '}
              <a
                className='underline text-white underline-offset-2 decoration-1'
                href='https://goto.intel.com/clientu'
                target='_blank'
                rel='noReferrer'
              >
                https://goto.intel.com/clientu
              </a>
            </p>
          </div>
          <button
            className={classNames('md:hidden text-energy-blue inline-flex pt-4 text-xs gap-2', {
              'pt-4': !shouldViewAllText,
            })}
            onClick={() => setShouldViewAllText(!shouldViewAllText)}
          >
            View {shouldViewAllText ? 'Less' : 'More'}
          </button>
        </div>
      </GameInsights>
    </Modal>
  )
}
export default InitialModal
