import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './app.css'
import '../src/assets/fonts/IntelOneDisplayLight.ttf'
import '../src/assets/fonts/IntelOneDisplayRegular.ttf'
import '../src/assets/fonts/IntelOneDisplayBold.ttf'
import RoutesLayout from './RoutesLayout'
import Sidebar from './components/organisms/Navbar/Sidebar'
import Navbar from './components/organisms/Navbar/Navbar'
import { actions } from './components/organisms/Modal/modal.reducers'
import renderModal from './components/organisms/AppModals'
import { selectSelectedModal } from './components/organisms/Modal'
const { setSelectedModal } = actions
import { fetchUserProfile, logout, selectUser } from './components/organisms/Auth'
import { MODALS, IS_AUTHENTICATED } from './constants'
import posthog from 'posthog-js'

const { LOGIN_MODAL, INITIAL_MODAL } = MODALS

const App = () => {
  const dispatch = useDispatch()
  const selectedModal = useSelector(selectSelectedModal)
  const userInfo = useSelector(selectUser)

  useEffect(() => {
    dispatch(fetchUserProfile()).then((res) => {
      if (res.payload) {
        localStorage.setItem(IS_AUTHENTICATED, true)
        posthog.group('environment', res.payload.issuer_group)
        posthog.identify(res.payload.email, res.payload.user)
      } else {
        dispatch(logout())
        localStorage.removeItem(IS_AUTHENTICATED)
        dispatch(setSelectedModal(LOGIN_MODAL))
      }
    })
  }, [dispatch])

  useEffect(() => {
    const isAuthenticated = localStorage.getItem(IS_AUTHENTICATED)
    if (Boolean(isAuthenticated)) dispatch(setSelectedModal(null))
    else if (userInfo) dispatch(setSelectedModal(INITIAL_MODAL))
    else dispatch(setSelectedModal(LOGIN_MODAL))
  }, [dispatch, userInfo])

  return (
    <Router>
      <div className='md:h-screen md:min-h-screen custom-scrollbar'>
        {renderModal(selectedModal)}
        <div className='flex justify-center md:items-center bg-color min-h-screen no-scroll-bar'>
          <Sidebar />
          <div className='relative flex-1 flex flex-col overflow-auto md:h-[100vh]'>
            <Navbar />
            <RoutesLayout />
          </div>
        </div>
      </div>
    </Router>
  )
}
export default App
