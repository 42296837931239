import Spinner from './Spinner'
import reducers from './spinner.reducer'
import * as selectors from './spinner.selectors'

export const {
  name,
  actions: { addSpinner, removeSpinner },
  reducer,
} = reducers

export const { selectSelectedSpinner } = selectors

export default Spinner
