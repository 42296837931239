const Footer = () => (
  <div>
    <p className='text-sm md:text-base text-secondary-light gap-1 text-justify'>
      Copyright &#169; Intel Corporation All rights reserved. Intel, the Intel logo, Intel Inside, the Intel Inside
      logo, Intel Core, Intel Evo, Intel vPro, Thunderbolt, and the Thunderbolt logo are trademarks of Intel Corporation
      or its subsidiaries. For questions or feedback, contact clientu@intel.com.
    </p>
  </div>
)

export default Footer
