import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { selectIsSidebarOpen } from '../../../pages/AppReducers'
import { homeIcon } from '../../../assets/icons'
import { actions } from '../Modal/modal.reducers'
const { setSelectedModal } = actions

const NavbarLink = ({ each }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname
  const isSidebarOpen = useSelector(selectIsSidebarOpen)
  const [showText, setShowText] = useState(true)

  const { title = 'Play Now', icon = homeIcon, path, handleClick, renderModal } = each

  const handleModalRendering = (modalName) => dispatch(setSelectedModal(modalName))

  const handleOnClick = () => {
    if (renderModal) handleModalRendering(renderModal)
    else handleClick()
  }

  useEffect(() => {
    isSidebarOpen ? setShowText(true) : setTimeout(() => setShowText(false), 300)
  }, [isSidebarOpen])

  return (
    <>
      <button
        className={`${!showText && 'justify-center'} ${
          path === currentPath && '!bg-primary'
        } flex items-center whitespace-nowrap gap-2 py-2 px-4 text-white text-sm font-semibold leading-6 hover:bg-primary/10 w-full cursor-pointer`}
        onClick={() => (handleClick ? handleOnClick() : navigate(path))}
      >
        <img
          src={icon}
          width='22'
          alt='link-icon'
          className={classNames({
            'opacity-60': path !== currentPath,
          })}
        />
        {showText && (
          <span
            className={
              (`transition-all ease-in-out duration-500 overflow-hidden`,
              classNames({
                'opacity-100': isSidebarOpen,
                'opacity-0': !isSidebarOpen,
                'text-slate-400': path !== currentPath,
              }))
            }
          >
            {title}
          </span>
        )}
      </button>
    </>
  )
}

export default NavbarLink
