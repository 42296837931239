import React from 'react'
import Modal from '../../Modal'
import Button from '../../../atoms/Button'
import { ROUTES } from '../../../../constants'
const { LOGIN } = ROUTES
const subtitle = `To play Digital Defender, you need to first log in through Intel's SSO platform.`
const title = 'Log In to Play Digital Defender'

const LoginModal = () => {
  const handleLogin = () => (window.location.href = LOGIN)

  const footer = (
    <div className='flex align-center justify-end'>
      <Button name='Sign in' classes='text-white content-end w-full md:w-auto' onClick={handleLogin} />
    </div>
  )

  return <Modal title={title} subtitle={subtitle} footer={footer}></Modal>
}
export default LoginModal
