import Leaderboard from './Leaderboard'
import * as selectors from './leaderboard.selectors'
import * as slice from './leaderboard.reducers'
import * as useQueryActions from './leaderboard.actions'

export const {
  name,
  actions: { setFilters },
  reducer,
} = slice
export const { fetchLeaderboard } = useQueryActions

export const { selectLeaderboardData, selectFilters } = selectors

export default Leaderboard
