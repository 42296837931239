import { createAsyncThunk } from '@reduxjs/toolkit'
import asyncAction from '../../../infrastructure/asyncAction/asyncAction'
import { HTTP_METHODS } from '../../../constants'
const { GET } = HTTP_METHODS

export const fetchUserProfile = createAsyncThunk('fetch-user-profile', async (args) => {
  return asyncAction({
    url: `profile`,
    methodType: GET,
    ...args,
  })
})
