import * as selectors from './app.selectors'
import * as slice from './app.reducers'

export const {
  name,
  actions: { setIsSidebarOpen },
  reducer,
} = slice

export const { selectIsSidebarOpen } = selectors
