import React, { useState } from 'react'
import classNames from 'classnames'
import KeyInstructions from './KeyInstructions'
import Instructions from './Instructions'
import { gameThumbnailSmall } from '../../assets/images'

const GameInsights = ({ isRenderedInModal, children }) => {
  const [shouldViewAllText, setShouldViewAllText] = useState(false)

  return (
    <div
      className={classNames('bg-black p-6 grid grid-cols-12 gap-6', {
        'p-1': isRenderedInModal,
      })}
    >
      <div
        className={classNames('col-span-2 hidden md:block', {
          '!hidden': isRenderedInModal,
        })}
      >
        <img
          src={gameThumbnailSmall}
          className={classNames('h-40 object-cover rounded border border-white')}
          alt='thumbnail'
        />
      </div>
      <Instructions />
      <div
        className={classNames('col-span-12 md:col-span-8 gap-1', {
          'col-span-12 md:col-span-9 mt-1': isRenderedInModal,
        })}
      >
        {isRenderedInModal ? (
          <>{children}</>
        ) : (
          <>
            <h4 className='font-bold text-white text-xl pb-2'>
              Survive the Vulnerability Onslaught In Digital Defender!
            </h4>
            <div className='text-sm overflow-auto scrollbar-hide text-secondary-light leading-relaxed'>
              <h3 className='subtitle text-white text-base leading-6 pb-4'>How to Play</h3>
              <p className='text-base text-secondary-light gap-1 overflow-auto'>
                In this game, you&apos;ll be presented with a series of knowledge blocks and quiz question, which will
                earn you powerful upgrades to enhance your mission. Complete each knowledge block and pass the quizzes
                to surv
                {shouldViewAllText
                  ? 'ive against attackers. If your health reaches zero or you answer incorrectly 3 times, it&apos;s &quot;Game Over&quot; for Defender. Simply tap &quot;Replay&quot; to start your defense over again. Quiz questions are random—every game is a unique challenge.'
                  : '...'}
              </p>
              {shouldViewAllText && (
                <div className='text-base text-secondary-light gap-1 overflow-auto scrollbar-hide'>
                  <h3 className='subtitle text-white text-base leading-6 py-4'>Accessibility</h3>
                  <p className='text-base text-secondary-light gap-1'>
                    To access commercial client training covering the launch of Intel® Core™ Ultra and Intel® Core™
                    processors (14th gen) that is accessible to users with disabilities, please visit the primary
                    ClientU web portal at:{' '}
                    <a
                      href='https://goto.intel.com/clientu'
                      target='_blank'
                      rel='noReferrer'
                      className='text-white underline'
                    >
                      https://goto.intel.com/clientu
                    </a>
                  </p>
                  <h3 className='subtitle text-white text-base leading-6 py-4'>Score with a High Score</h3>
                  <p className='text-base text-secondary-light gap-1'>
                    Complete the training, push past Digital Defender&apos;s enemies, and etch your name on the
                    leaderboard to show off to your colleagues. At the beginning of every quarter of 2025, the
                    leaderboard will be reset. The knowledge blocks and quiz questions will be refreshed periodically to
                    reflect the latest commercial client product launches. Only Intel full time employees (Blue Badge
                    holders) are eligible for the leaderboard.
                  </p>
                  <ul className='list-disc pl-6 list-[square]'>
                    <li>
                      A user may only have one high score on the leaderboard per quarter. If a user replays the game and
                      generates a higher score, their previous high score is replaced with the new high score.
                    </li>
                  </ul>
                  <h3 className='subtitle text-white text-base leading-6 py-4'>Leaderboard Opt In</h3>
                  <p className='text-base text-secondary-light gap-1'>
                    Upon completion of this training and hitting &quot;Submit&quot; on the game-over screen, your name
                    may be shared on the game leaderboard and in our internal communication channels such as Viva Engage
                    (formerly Yammer) and Circuit+ for recognition and program promotion efforts only; no other personal
                    data is included.
                  </p>
                  <p className='text-base pt-4 text-secondary-light gap-1'>
                    If you would like your name to be excluded from the leaderboard and internal communications, close
                    the game over screen without hitting &quot;Submit.&quot; For more information on how Intel manages
                    your personal data, see the{' '}
                    <a
                      href='http://employeecontent.intel.com/content/corp/Global_Employee_and_Global_Contingent_Worker_Privacy.html'
                      target='_blank'
                      className='subtitle text-base leading-6 text-white pt-1'
                      rel='noReferrer'
                    >
                      Global Employee and Contingent Worker Privacy Notice.
                    </a>
                  </p>
                </div>
              )}
            </div>

            <button
              className={classNames('text-energy-blue inline-flex pt-4 text-xs gap-2', {
                'pt-8': !shouldViewAllText,
              })}
              onClick={() => setShouldViewAllText(!shouldViewAllText)}
            >
              View {shouldViewAllText ? 'Less' : 'More'}
            </button>
          </>
        )}
      </div>
      <KeyInstructions isRenderedInModal={isRenderedInModal} />
    </div>
  )
}

export default GameInsights
