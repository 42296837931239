import classNames from 'classnames'
import { bottomArrow, leftArrow, rightArrow, topArrow } from '../../assets/icons'

const KeyInstructions = ({ isRenderedInModal = false }) => {
  return (
    <div
      className={classNames('col-span-2 hidden md:block', {
        'col-span-3': isRenderedInModal,
      })}
    >
      <div className='bg-secondary p-4 flex flex-col'>
        <div className='mb-4'>
          <h4 className='text-sm font-medium text-white mb-1'>Instructions</h4>
          <p className='text-xs 2xl:text-sm text-secondary-light'>
            To move Defender, use the left and right arrow keys.
          </p>
        </div>
        <div className='grid grid-cols-3 gap-1 flex-1'>
          <div className={classNames('bg-black p-2 rounded-md grid place-content-center border-2 border-indigo-600')}>
            <img src={leftArrow} width='7' alt='left-arrow' />
          </div>
          <div className='flex flex-col gap-1'>
            <div
              className={classNames(
                'bg-[#10151b] p-2 rounded-md grid place-content-center flex-1 border-2 border-[#10151b]'
              )}
            >
              <img src={topArrow} width='7' alt='top-arrow' />
            </div>
            <div
              className={classNames(
                'bg-[#10151b] p-2 rounded-md grid place-content-center flex-1 border-2 border-[#10151b]'
              )}
            >
              <img src={bottomArrow} width='7' alt='bottom-arrow' />
            </div>
          </div>
          <div className={classNames('bg-black p-2 rounded-md grid place-content-center border-2 border-indigo-600')}>
            <img src={rightArrow} width='7' alt='right-arrow' />
          </div>
        </div>
      </div>
    </div>
  )
}
export default KeyInstructions
