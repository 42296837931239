import bronzeBadge from './bronzeBadge.svg'
import silverBadge from './silverBadge.svg'
import goldBadge from './goldBadge.svg'

import fire_0 from './fire_0.svg'
import fire_1 from './fire_1.svg'
import fire_2 from './fire_2.svg'
import fire_3 from './fire_3.svg'
import fire_4 from './fire_4.svg'
import fire_5 from './fire_5.svg'
import fire_6 from './fire_6.svg'
import freeze_0 from './freeze_0.svg'
import freeze_1 from './freeze_1.svg'
import freeze_2 from './freeze_2.svg'
import freeze_3 from './freeze_3.svg'
import freeze_4 from './freeze_4.svg'
import freeze_5 from './freeze_5.svg'
import freeze_6 from './freeze_6.svg'
import health_0 from './health_0.svg'
import health_1 from './health_1.svg'
import health_2 from './health_2.svg'
import health_3 from './health_3.svg'
import health_4 from './health_4.svg'
import health_5 from './health_5.svg'
import health_6 from './health_6.svg'
import speed_0 from './speed_0.svg'
import speed_1 from './speed_1.svg'
import speed_2 from './speed_2.svg'
import speed_3 from './speed_3.svg'
import speed_4 from './speed_4.svg'
import speed_5 from './speed_5.svg'
import speed_6 from './speed_6.svg'
import progress_0 from './progress_0.svg'
import progress_1 from './progress_1.svg'
import progress_2 from './progress_2.svg'
import progress_3 from './progress_3.svg'
import progress_4 from './progress_4.svg'
import progress_5 from './progress_5.svg'
import progress_6 from './progress_6.svg'
import shield_0 from './shield_0.svg'
import shield_1 from './shield_1.svg'
import shield_2 from './shield_2.svg'
import shield_3 from './shield_3.svg'
import shield_4 from './shield_4.svg'
import shield_5 from './shield_5.svg'
import shield_6 from './shield_6.svg'
import side_0 from './side_0.svg'
import side_1 from './side_1.svg'
import side_2 from './side_2.svg'
import side_3 from './side_3.svg'
import side_4 from './side_4.svg'
import side_5 from './side_5.svg'
import side_6 from './side_6.svg'
import spread_0 from './spread_0.svg'
import spread_1 from './spread_1.svg'
import spread_2 from './spread_2.svg'
import spread_3 from './spread_3.svg'
import spread_4 from './spread_4.svg'
import spread_5 from './spread_5.svg'
import spread_6 from './spread_6.svg'
import laser_0 from './laser_0.svg'
import laser_1 from './laser_1.svg'
import laser_2 from './laser_2.svg'
import laser_3 from './laser_3.svg'
import laser_4 from './laser_4.svg'
import laser_5 from './laser_5.svg'
import laser_6 from './laser_6.svg'

const IMAGE = {
  ['fire_0']: fire_0,
  ['fire_3']: fire_1,
  ['fire_2']: fire_2,
  ['fire_3']: fire_3,
  ['fire_4']: fire_4,
  ['fire_5']: fire_5,
  ['fire_6']: fire_6,
  ['freeze_0']: freeze_0,
  ['freeze_1']: freeze_1,
  ['freeze_2']: freeze_2,
  ['freeze_3']: freeze_3,
  ['freeze_4']: freeze_4,
  ['freeze_5']: freeze_5,
  ['freeze_6']: freeze_6,
  ['health_0']: health_0,
  ['health_1']: health_1,
  ['health_2']: health_2,
  ['health_3']: health_3,
  ['health_4']: health_4,
  ['health_5']: health_5,
  ['health_6']: health_6,
  ['shield_0']: shield_0,
  ['shield_1']: shield_1,
  ['shield_2']: shield_2,
  ['shield_3']: shield_3,
  ['shield_4']: shield_4,
  ['shield_5']: shield_5,
  ['shield_6']: shield_6,
  ['speed_0']: speed_0,
  ['speed_1']: speed_1,
  ['speed_2']: speed_2,
  ['speed_3']: speed_3,
  ['speed_4']: speed_4,
  ['speed_5']: speed_5,
  ['speed_6']: speed_6,
  ['progress_0']: progress_0,
  ['progress_1']: progress_1,
  ['progress_2']: progress_2,
  ['progress_3']: progress_3,
  ['progress_4']: progress_4,
  ['progress_5']: progress_5,
  ['progress_6']: progress_6,
  ['spread_0']: spread_0,
  ['spread_1']: spread_1,
  ['spread_2']: spread_2,
  ['spread_3']: spread_3,
  ['spread_4']: spread_4,
  ['spread_5']: spread_5,
  ['spread_6']: spread_6,
  ['side_0']: side_0,
  ['side_1']: side_1,
  ['side_2']: side_2,
  ['side_3']: side_3,
  ['side_4']: side_4,
  ['side_5']: side_5,
  ['side_6']: side_6,
  ['laser_0']: laser_0,
  ['laser_1']: laser_1,
  ['laser_2']: laser_2,
  ['laser_3']: laser_3,
  ['laser_4']: laser_4,
  ['laser_5']: laser_5,
  ['laser_6']: laser_6,
  ['goldBadge']: goldBadge,
  ['silverBadge']: silverBadge,
  ['bronzeBadge']: bronzeBadge,
}

const RenderImage = ({ name }) => <img src={IMAGE[name]} alt={`${name}_badge`} className='w-6 md:w-10' />
export { goldBadge, silverBadge, bronzeBadge }

export default RenderImage
