import { MODALS } from '../../../constants'
import AccessDeniedModal from './AccessDeniedModal/AccessDeniedModal'
import DisclaimerModal from './DisclaimerModal/DisclaimerModal'
import InitialModal from './InitialModal/InitialModal'
import LoginModal from './LoginModal/LoginModal'

const { ACCESS_DENIED_MODAL, LOGIN_MODAL, INITIAL_MODAL, DISCLAIMER_MODAL } = MODALS

const renderModal = (name) =>
  ({
    [INITIAL_MODAL]: <InitialModal />,
    [ACCESS_DENIED_MODAL]: <AccessDeniedModal />,
    [LOGIN_MODAL]: <LoginModal />,
    [DISCLAIMER_MODAL]: <DisclaimerModal />,
  }[name] || null)

export default renderModal
