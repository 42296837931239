import RenderImage from '../assets/badges'

const getFileName = (name) => name.replace(/_level/, '')

const badgesFormatter = (images) => (
  <div className='flex'>
    {images &&
      images.map(
        (eachImg, i) =>
          // filter out core_health_* or movement_speed_*
          !eachImg.includes('core_health_') &&
          !eachImg.includes('movement_speed_') && <RenderImage name={getFileName(eachImg)} key={i} />
      )}
  </div>
)

export default badgesFormatter
