import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import storeConfiguration from './storeConfiguration'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'

posthog.init(process.env.REACT_APP_POSTHOG_API_KEY || 'phc_JNn7VbooknSmCk9L8GNGKf9V1xWHEsBDtlSq6f7lmIn', {
  api_host: 'https://app.posthog.com',
  session_recording: {
    recordCrossOriginIframes: true,
  },
})

Sentry.init({
  dsn: 'https://539dd8a4c38acb604a00b4bec573a5cd@o4506735078146048.ingest.sentry.io/4506788331913216',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /.*\.ccg-game\.training/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const store = storeConfiguration()
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
