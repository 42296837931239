import * as selectors from './auth.selectors'
import * as slice from './auth.reducers'
import * as queryActions from './auth.actions'

export const {
  name,
  actions: { logout },
  reducer,
} = slice

export const { fetchUserProfile } = queryActions

export const { selectUser } = selectors
